import { create } from "zustand"

interface SleepSummaryStore {
  currentSleepResponse: SleepSummaryResponse | null
  setCurrentSleepResponse: (response: SleepSummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
}

const useSleepSummaryStore = create<SleepSummaryStore>((set) => ({
  currentSleepResponse: null,
  setCurrentSleepResponse: (currentSleepResponse) => set({ currentSleepResponse }),
  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
}))

export default useSleepSummaryStore
