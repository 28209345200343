import { useEffect, useRef } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import MoodStackGraphD3 from "../graph/D3Files/MoodStackGraphD3"
import MoodInsightAnswerList from "./MoodInsightAnswerList"
import useMoodInsightStore from "./store/MoodInsightStore"

const MoodInsightChart: React.FC = () => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useResizeWindow(containerRef)

  const currentActiveMoodPanel = useMoodInsightStore((state) => state.currentActiveMoodPanel)
  const setCurrentActiveMoodPanel = useMoodInsightStore((state) => state.setCurrentActiveMoodPanel)
  const currentGraphData = useMoodInsightStore((state) => state.currentMoodResponse)

  const isValidSize = containerSize.width > 0 && containerSize.height > 0

  const daysPerPage = 7

  const dateStringToDisplay = (dateString: string, showYear: boolean) => {
    const [year, month, day] = dateString.split("-")
    const date = new Date(Number(year), Number(month) - 1, Number(day))
    const dayOfWeek = date.toLocaleString("default", { weekday: "short" })
    const shortMonth = date.toLocaleString("default", { month: "short" })
    return showYear ? `${shortMonth} ${day} ${year}` : `${shortMonth} ${day}, ${dayOfWeek}`
  }

  const getPageName = (displayedData: InsightReportMoodData | null) => {
    if (!displayedData || Object.keys(displayedData).length === 0) {
      return "No data"
    }
    const datesStringList = Object.keys(displayedData).sort()
    return (
      `${dateStringToDisplay(datesStringList[0], false)} - ` +
      dateStringToDisplay(datesStringList[datesStringList.length - 1], false)
    )
  }

  const sortedDates = Object.keys(currentGraphData || {}).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

  const paginatedData = sortedDates.slice(
    currentActiveMoodPanel * daysPerPage,
    currentActiveMoodPanel * daysPerPage + daysPerPage,
  )

  const displayedData = paginatedData.reduce(
    (acc, date) => {
      if (currentGraphData && acc && currentGraphData[date]) {
        acc[date] = currentGraphData[date]
      }
      return acc
    },
    {} as typeof currentGraphData,
  )

  const getHeightStyle = (data: typeof currentGraphData) => {
    if (!data) return "h-60"
    let maxItemLength = 0
    for (const key in data) {
      if (data[key].length > maxItemLength) {
        maxItemLength = data[key].length
      }
    }
    if (maxItemLength > 12) {
      return "h-144"
    } else if (maxItemLength >= 8) {
      return "h-96"
    } else {
      return "h-60"
    }
  }

  const heightStyle = getHeightStyle(displayedData)
  const totalPages = Math.ceil(sortedDates.length / daysPerPage)

  useEffect(() => {
    if (isValidSize && chartArea.current) {
      new MoodStackGraphD3(chartArea.current, containerSize.width, containerSize.height, displayedData)
    }
  }, [containerSize, displayedData, isValidSize])

  return (
    <div className="w-full h-auto cursor-auto">
      <div className="flex flex-col items-center mt-4 w-full">
        <div className="flex justify-between w-full">
          <button
            className="px-4 py-2 border border-neutral-100 rounded-full hover:bg-gray-300 disabled:text-neutral-100"
            disabled={currentActiveMoodPanel === 0}
            onClick={() => setCurrentActiveMoodPanel(Math.max(currentActiveMoodPanel - 1, 0))}
          >
            Previous
          </button>
          <div>
            <div className="px-4 py-2 border border-neutral-100 rounded-full min-w-72 text-center">
              {getPageName(displayedData)}
            </div>
          </div>
          <button
            className="px-4 py-2 border border-neutral-100 rounded-full hover:bg-gray-300 disabled:text-neutral-100"
            disabled={currentActiveMoodPanel === totalPages - 1}
            onClick={() => setCurrentActiveMoodPanel(Math.min(currentActiveMoodPanel + 1, totalPages - 1))}
          >
            Next
          </button>
        </div>
      </div>

      <div className={`w-full overflow-hidden ${heightStyle}`} ref={containerRef}>
        <div className="chart-area flex-1 w-full h-full overflow-hidden" ref={chartArea}></div>
      </div>

      <MoodInsightAnswerList data={displayedData} />
    </div>
  )
}

export default MoodInsightChart
