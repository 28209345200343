import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface SmallControlButtonProps {
  onClick: () => void
  icon: IconDefinition
  direction?: "left" | "right"
  className?: string
}

const SmallControlButton: React.FC<SmallControlButtonProps> = ({ onClick, icon, direction, className = "" }) => {
  const baseClasses =
    "rounded-xl bg-white px-2 py-1 text-xs font-medium transition-colors " +
    "duration-200 ease-in-out hover:bg-gray-100 active:bg-gray-300"

  let directionClasses = ""
  if (direction === "left") {
    directionClasses = "-ml-2"
  } else if (direction === "right") {
    directionClasses = "-mr-2"
  }

  return (
    <button onClick={onClick} className={`${baseClasses} ${directionClasses} ${className}`}>
      <FontAwesomeIcon icon={icon} />
    </button>
  )
}

export default SmallControlButton
