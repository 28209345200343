import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/shadcn-components/ui/dialog.tsx"

import ClinicianInspectPolicies from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/ClinicianInspectPolicies.tsx"

import ClinicianRelationshipsProvider from "@features/Home/providers/ClinicianRelationshipsProvider.tsx"

const InspectPermissionsDialog = ({ row, triggerProps }: DropdownTriggeredDialogProps<ListUser>) => {
  const targetUser: ListUser = row.original

  return (
    <Dialog {...triggerProps}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Inspect Permissions</DialogTitle>
          <DialogDescription>
            Below are the permissions the user has selected for sharing data with you. If the permissions are not what
            you expect, you will need to ask the client to update them.
          </DialogDescription>
          <ClinicianRelationshipsProvider clientUser={targetUser}>
            <ClinicianInspectPolicies />
          </ClinicianRelationshipsProvider>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default InspectPermissionsDialog
