import { FormEvent } from "react"

import RAChangePasswordForm from "@features/Home/components/RAHome/RAChangePasswordForm.tsx"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

const RAChangePassword = ({
  afterClick,
  userId,
}: {
  afterClick: React.Dispatch<React.SetStateAction<boolean>>
  userId: string
}) => {
  const patchPassword = async (password: string, user_id: string) => {
    try {
      const response = await api.patch<{ error: string }>(`/idp/set_password/${user_id}`, { new_password: password })
      if (response.status === 200) {
        success("Password changed successfully", true)
        afterClick(false)
      } else {
        error(response.data.error, true)
      }
    } catch (err) {
      error((err as Error).message, true)
    }
  }
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const password = form.elements.namedItem("password") as HTMLInputElement
    const confirmPassword = form.elements.namedItem("confirmPassword") as HTMLInputElement

    if (password.value !== confirmPassword.value) {
      alert("Passwords do not match")
      return
    }
    void patchPassword(password.value, userId)
  }

  return (
    <div className={"rounded-xl w-full"}>
      <RAChangePasswordForm handleSubmit={handleSubmit} />
    </div>
  )
}

export default RAChangePassword
