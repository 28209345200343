import { useUser } from "@clerk/clerk-react"
import { Row } from "@tanstack/react-table"

import { config } from "@/config.ts"
import { ROLES } from "@/constants/roles.const.ts"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/shadcn-components/ui/dropdown-menu.tsx"

import ActionMenuTrigger from "@components/Menus/ActionMenu/ActionMenuTrigger.tsx"
import CopyRecordId from "@components/Menus/ActionMenu/CopyRecordId.tsx"
import AddAssignmentsDialog from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignmentsDialog.tsx"
import AddLinkDialog from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/AddLinkDialog.tsx"
import ChangePasswordRADialog from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/ChangePasswordRADialog.tsx"
import InspectPermissionsDialog from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/InspectPermissionsDialog.tsx"
import ManageCliniciansDialog from "@components/Widgets/UsersTable/DropdownMenuItems/ManageCliniciansDialog.tsx"
import RevokeRelationshipDialog from "@components/Widgets/UsersTable/DropdownMenuItems/RevokeRelationshipDialog.tsx"

import { useDialog } from "@utilities/shadcn-utils.ts"

interface Props {
  row: Row<ListUser>
  useCase: "clinician_home" | "group_members" | "admin"
  additionalButtons?: DropdownMenuButton[]
}

const UsersTableDropdownMenu = ({ row, useCase, additionalButtons = [] }: Props) => {
  const user = row.original
  const { user: currentUser } = useUser()

  const assignClinicianDialog = useDialog()
  const addAssignmentsDialog = useDialog()
  const revokeRelationshipDialog = useDialog()
  const addLinkDialog = useDialog()
  const inspectPermissionsDialog = useDialog()
  const changePasswordRADialog = useDialog()

  const clerkUrl = `https://dashboard.clerk.com/apps/${config.clerk.app_id}/instances/${config.clerk.instance_id}/users/${user.idp_sub}`

  const buttons: DropdownMenuButton[] = [
    {
      useCases: "all",
      component: <DropdownMenuLabel>Actions</DropdownMenuLabel>,
    },
    {
      useCases: "all",
      component: <CopyRecordId value={user.user_id} />,
    },
    {
      useCases: "all",
      component: <CopyRecordId label={"Copy Email"} value={user.email} />,
    },
    {
      useCases: "all",
      component: <DropdownMenuSeparator />,
    },
    {
      useCases: ["clinician_home", "group_members"],
      component: <DropdownMenuItem onSelect={addAssignmentsDialog.trigger}>Add Assignments</DropdownMenuItem>,
    },
    {
      useCases: ["admin"],
      component: <DropdownMenuItem onSelect={assignClinicianDialog.trigger}>Assign Clinician</DropdownMenuItem>,
    },
    {
      useCases: ["clinician_home"],
      component: (
        <DropdownMenuItem onSelect={revokeRelationshipDialog.trigger}>Disconnect from Patient</DropdownMenuItem>
      ),
    },
    {
      useCases: ["clinician_home"],
      component: <DropdownMenuItem onSelect={addLinkDialog.trigger}>Add Link to Feed</DropdownMenuItem>,
    },
    {
      useCases: ["clinician_home"],
      component: <DropdownMenuItem onSelect={inspectPermissionsDialog.trigger}>Inspect Permissions</DropdownMenuItem>,
    },
    {
      useCases: ["clinician_home"],
      permittedRoles: [ROLES.RA],
      component: <DropdownMenuItem onSelect={changePasswordRADialog.trigger}>Change Password (RA)</DropdownMenuItem>,
    },
    {
      useCases: ["admin"],
      component: (
        <>
          <DropdownMenuSeparator />
          <a target={"_blank"} rel={"noopener noreferrer"} href={clerkUrl}>
            <DropdownMenuItem>Open in Clerk</DropdownMenuItem>
          </a>
        </>
      ),
    },
    ...additionalButtons,
  ]

  const currentUserRoles: string[] = (currentUser?.publicMetadata?.roles as string[]) ?? ([] as string[])

  return (
    <>
      {/* -- Dialogs -- */}
      <ManageCliniciansDialog row={row} triggerProps={assignClinicianDialog.props} />
      <AddAssignmentsDialog row={row} triggerProps={addAssignmentsDialog.props} />
      <RevokeRelationshipDialog row={row} triggerProps={revokeRelationshipDialog.props} />
      <AddLinkDialog row={row} triggerProps={addLinkDialog.props} />
      <InspectPermissionsDialog row={row} triggerProps={inspectPermissionsDialog.props} />
      <ChangePasswordRADialog row={row} triggerProps={changePasswordRADialog.props} />
      {/* -- End Dialogs -- */}

      <DropdownMenu modal={false}>
        <ActionMenuTrigger />
        <DropdownMenuContent align="end">
          {buttons.map((button: DropdownMenuButton) => {
            if (button.useCases === "all" || button.useCases.includes(useCase)) {
              if (button.permittedRoles) {
                const isPermitted = button.permittedRoles.some((role) => currentUserRoles.includes(role))
                if (!isPermitted) {
                  return null
                }
              }
              return button.component
            }
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
export default UsersTableDropdownMenu
