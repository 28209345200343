import ActivityStackGraph from "../graph/ActivityStackGraph"
import SleepAndStepsGraphTabs from "./SleepAndStepsGraphTabs"
import useActivityInsightStore from "./store/ActivityInsightStore"

const SleepAndStepsCard: React.FC = () => {
  const currentGraphData = useActivityInsightStore((state) => state.currentActivityResponse)
  if (!currentGraphData) return null

  return (
    <div className="w-full h-full flex flex-col items-center">
      <ActivityStackGraph graphData={currentGraphData} />
      <SleepAndStepsGraphTabs />
    </div>
  )
}

export default SleepAndStepsCard
