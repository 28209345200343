import { create } from "zustand"

interface HomeTimeSummaryStore {
  currentHomeTimeResponse: HomeTimeSummaryResponse | null
  setCurrentHomeTimeResponse: (response: HomeTimeSummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
}

const useHomeTimeSummaryStore = create<HomeTimeSummaryStore>((set) => ({
  currentHomeTimeResponse: null,
  setCurrentHomeTimeResponse: (currentHomeTimeResponse) => set({ currentHomeTimeResponse }),
  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
}))

export default useHomeTimeSummaryStore
