import { useEffect, useRef } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"
import useActivityInsightStore from "@features/Insight/components/activity/store/ActivityInsightStore.js"

import ActivityAggregateGraphD3 from "./D3Files/ActivityAggregateGraphD3.js"
import ActivityStackGraphD3 from "./D3Files/ActivityStackGraphD3.js"

interface ActivityStackGraphProps {
  graphData?: ActivityStackResponse
}

const ActivityStackGraph = ({ graphData }: ActivityStackGraphProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0

  const { currentGraphFormat } = useActivityInsightStore()

  useEffect(() => {
    const hasValidData = graphData && Object.keys(graphData).length > 0

    if (isValidSize && hasValidData && chartArea.current && containerSize.width && containerSize.height) {
      if (currentGraphFormat == "Timeline") {
        new ActivityStackGraphD3(chartArea.current, containerSize.width, containerSize.height, graphData)
      } else if (currentGraphFormat == "Sleep Aggregate") {
        new ActivityAggregateGraphD3(chartArea.current, containerSize.width, containerSize.height, graphData)
      }
    }
  }, [containerSize, graphData, isValidSize, currentGraphFormat])

  return (
    <div className="w-full h-auto overflow-hidden" ref={containerRef}>
      <div className="chart-area flex-1 w-full h-128 overflow-hidden" ref={chartArea}></div>
    </div>
  )
}

export default ActivityStackGraph
