import { z } from "zod"

import { Button } from "@/shadcn-components/ui/button.tsx"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/shadcn-components/ui/dialog.tsx"

import FormBuilderV2, { FormField, zodFormSchemaBuilder } from "@components/Forms/FormBuilderV2.tsx"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

interface AddLinkPayload {
  target_user_id: string
  url: string
  name: string
  days_until_available?: number | undefined
  days_available?: number | undefined
  description?: string | undefined
}

const AddLinkDialog = ({ row, triggerProps }: DropdownTriggeredDialogProps<ListUser>) => {
  const user = row.original

  const formFields: FormField[] = [
    {
      label: "URL",
      type: "url",
      name: "url",
      placeholder: "https://www.google.com",
      zSchema: z.string().min(2, {
        message: "Must be at least 2 characters long",
      }),
    },
    {
      label: "Name",
      type: "text",
      name: "name",
      placeholder: "Google",
      zSchema: z.string().min(2, {
        message: "Must be at least 2 characters long",
      }),
    },
    {
      label: "Description",
      type: "text",
      name: "description",
      placeholder: "A search engine",
      zSchema: z.string(),
    },
    {
      label: "Days Until Available",
      type: "number",
      name: "daysUntilAvailable",
      placeholder: "0",
      min: 0,
      zSchema: z.coerce.number().int().min(0, {
        message: "Must be a positive number",
      }),
    },
    {
      label: "Days Available",
      type: "number",
      name: "daysAvailable",
      placeholder: "1",
      min: 0,
      zSchema: z.coerce.number().int().min(0, {
        message: "Must be a positive number",
      }),
    },
  ]

  const defaultValues: {
    url: string
    name: string
    description: string
    daysUntilAvailable: number
    daysAvailable: number
  } = {
    url: "",
    name: "",
    description: "",
    daysUntilAvailable: 0,
    daysAvailable: 1,
  }

  const formSchema = zodFormSchemaBuilder(formFields)

  const postNewLink = async (payload: AddLinkPayload) => {
    try {
      const response = await api.post<{ error: string }>(`/links/create_feed_link`, payload)
      if (response.status === 200) {
        success("Link added successfully", true)
        triggerProps.onOpenChange(false)
      } else {
        error(response.data.error, true)
      }
    } catch (err) {
      error((err as Error).message, true)
    }
  }

  const handleSubmit = (data: z.infer<typeof formSchema>) => {
    const payload: AddLinkPayload = {
      target_user_id: user.user_id,
      url: data.url as string,
      name: data.name as string,
      days_until_available: (data.daysUntilAvailable ?? undefined) as number | undefined,
      days_available: (data.daysAvailable ?? undefined) as number | undefined,
      description: data.description as string,
    }
    void postNewLink(payload)
  }

  if (triggerProps.open)
    return (
      <Dialog {...triggerProps}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Link to Client's Feed</DialogTitle>
            <DialogDescription>
              Adding a link to a client's feed will allow them to view and interact with the content.
            </DialogDescription>
          </DialogHeader>
          <FormBuilderV2 formFields={formFields} onSubmit={handleSubmit} defaultValues={defaultValues}>
            <DialogFooter>
              <Button type="submit">Add Link</Button>
            </DialogFooter>
          </FormBuilderV2>
        </DialogContent>
      </Dialog>
    )

  return null
}

export default AddLinkDialog
