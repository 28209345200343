import { ColumnDef } from "@tanstack/react-table"
import { Link } from "react-router-dom"

import Button from "@components/Buttons/Button.tsx"
import Card from "@components/Cards/Card.tsx"
import { DataTableColumnHeader } from "@components/Tables/DataTable.tsx"
import UsersTable from "@components/Widgets/UsersTable/UsersTable.tsx"
import UsersTableDropdownMenu from "@components/Widgets/UsersTable/UsersTableDropdownMenu.tsx"

interface Props {
  CardProps?: CardTypeProps
}

const ClinicianClients = ({ CardProps }: Props) => {
  const columns: ColumnDef<ListUser>[] = [
    {
      id: "Display Name",
      accessorKey: "display_name",
      header: ({ column }) => <DataTableColumnHeader column={column} title={"Name"} />,
      cell: ({ row }) => {
        const user = row.original
        return (
          <Link to={`/insight/${user.user_id}`}>
            <div className="flex items-center">
              <span>{user.display_name}</span>
            </div>
          </Link>
        )
      },
    },
    {
      id: "Sex",
      accessorKey: "sex",
      header: ({ column }) => <DataTableColumnHeader column={column} title={"Sex"} />,
    },
    {
      id: "Age",
      accessorKey: "age",
      header: ({ column }) => <DataTableColumnHeader column={column} title={"Age"} />,
    },
    {
      id: "Overview",
      cell: ({ row }) => {
        const user = row.original
        return (
          <Button size="small" className="-mr-1" to={`/pr/clients/${user.user_id}`}>
            See Overview
          </Button>
        )
      },
    },
    {
      id: "Actions",
      cell: ({ row }) => <UsersTableDropdownMenu row={row} useCase={"clinician_home"} />,
    },
  ]

  return (
    <Card {...CardProps}>
      <UsersTable title={"My Clients"} endpoint={"/users/associated"} columns={columns} />
    </Card>
  )
}
export default ClinicianClients
