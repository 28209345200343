import { useEffect, useRef } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import SurveyScoresGraphD3 from "./D3Files/SurveyScoresGraphD3.js"

interface SurveyScoresGraphProps {
  graphData?: SurveyScoresDetailsItem | null
}

const SurveyScoresGraph = ({ graphData }: SurveyScoresGraphProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const currentGraphData = graphData
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0

  useEffect(() => {
    if (isValidSize) {
      new SurveyScoresGraphD3(chartArea.current, containerSize.width, containerSize.height, currentGraphData)
    }
  }, [containerSize, graphData, currentGraphData, isValidSize])

  return (
    <div className="w-full rounded-xl flex flex-col mb-6">
      <div className="w-full h-auto overflow-hidden" ref={containerRef}>
        <div className={`chart-area flex-1 w-full overflow-hidden h-60`} ref={chartArea}></div>
      </div>
    </div>
  )
}

export default SurveyScoresGraph
