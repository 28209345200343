import ActivitiesSummarySection from "./ActivitiesSummarySection"
import SurveysSummarySection from "./SurveysSummarySection"
import useHomeTimeSummaryStore from "./store/HomeTimeSummaryStore"
import useSleepSummaryStore from "./store/SleepSummaryStore"
import useStepSummaryStore from "./store/StepSummaryStore"

const SummaryPage: React.FC = () => {
  const setHomeTimeOffsetDays = useHomeTimeSummaryStore((state) => state.setCurrentOffsetDays)
  const setSleepOffsetDays = useSleepSummaryStore((state) => state.setCurrentOffsetDays)
  const setStepOffsetDays = useStepSummaryStore((state) => state.setCurrentOffsetDays)

  setHomeTimeOffsetDays(0)
  setSleepOffsetDays(0)
  setStepOffsetDays(0)

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Summary</h3>
      </div>

      <ActivitiesSummarySection />
      <SurveysSummarySection />
    </div>
  )
}

export default SummaryPage
