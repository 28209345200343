import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"
import DensityGraphAnimated from "../graph/DensityGraphAnimated"
import MoodInsightChart from "./MoodInsightChart"
import MoodRangeSelector from "./MoodRangeSelector"
import useMoodInsightStore from "./store/MoodInsightStore"

const MoodMapSection: React.FC = () => {
  const cardStyle = `border bg-white rounded-xl w-full cursor-pointer shadow-none transition duration-300 ease-in-out p-2 `
  const moodChartCardStyle = `border w-full shadow-none`

  const currentActiveMoodPanel = useMoodInsightStore((state) => state.currentActiveMoodPanel)
  const setCurrentActiveMoodPanel = useMoodInsightStore((state) => state.setCurrentActiveMoodPanel)

  const getHightlightedStyle = (panel: number) => {
    if (currentActiveMoodPanel === panel) {
      return "shadow-xl"
    }
  }
  return (
    <>
      <SectionContainer
        title="Mood Map"
        description="Mood entries over the 3 weeks window"
        headerControls={<MoodRangeSelector />}
      >
        <div className="flex gap-3 mt-3">
          <div className={cardStyle + getHightlightedStyle(0)} onClick={() => setCurrentActiveMoodPanel(0)}>
            <div className="w-full aspect-square">
              <DensityGraphAnimated startFromIndex={0} />
            </div>
          </div>
          <div className={cardStyle + getHightlightedStyle(1)} onClick={() => setCurrentActiveMoodPanel(1)}>
            <div className="w-full aspect-square">
              <DensityGraphAnimated startFromIndex={1} />
            </div>
          </div>
          <div className={cardStyle + getHightlightedStyle(2)} onClick={() => setCurrentActiveMoodPanel(2)}>
            <div className="w-full aspect-square">
              <DensityGraphAnimated startFromIndex={2} />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <Card additionalClasses={moodChartCardStyle}>
            <MoodInsightChart />
          </Card>
        </div>
      </SectionContainer>
    </>
  )
}

export default MoodMapSection
