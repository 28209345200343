import { zodResolver } from "@hookform/resolvers/zod"
import { CalendarIcon } from "@radix-ui/react-icons"
import { format } from "date-fns"
import { SelectSingleEventHandler } from "react-day-picker"
import { UseFormReturn, useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/shadcn-components/ui/button"
import { Calendar } from "@/shadcn-components/ui/calendar.tsx"
import { Checkbox } from "@/shadcn-components/ui/checkbox.tsx"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/shadcn-components/ui/form.tsx"
import { Input } from "@/shadcn-components/ui/input.tsx"
import { Popover, PopoverContent, PopoverTrigger } from "@/shadcn-components/ui/popover"
import { RadioGroup, RadioGroupItem } from "@/shadcn-components/ui/radio-group.tsx"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/shadcn-components/ui/select.tsx"
import { Textarea } from "@/shadcn-components/ui/textarea.tsx"

import { cn } from "@utilities/shadcn-utils.ts"

type PassedForm = UseFormReturn<Record<string, string>, z.ZodTypeAny, undefined>

interface IDecoratedFormField {
  field: {
    name: string
    value: string | number | readonly string[] | undefined
    onChange: ((e: React.ChangeEvent<HTMLInputElement>) => void) &
      ((value: string) => void) &
      ((checked: boolean) => void) &
      ((e: React.ChangeEvent<HTMLTextAreaElement>) => void)
    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  }
  formField: FormField
  form: PassedForm
}

export interface FormField {
  label: string
  name: string
  type: FormBuilderV2FieldTypes
  placeholder?: string
  defaultValue?: ""
  zSchema: z.ZodTypeAny & { options?: string[] }
  min?: number
  options?: { label: string; value: string }[]
}

interface FormBuilderV2Props {
  formFields: FormField[]
  onSubmit: (values: z.infer<ReturnType<typeof z.object>>) => void
  defaultValues: Record<string, unknown>
  children?: React.ReactNode
}

const DecoratedFormFieldContent = ({ field, formField, form }: IDecoratedFormField) => {
  if (!formField.zSchema) return "Form must have zod schema defined"
  if (formField.type === "select") {
    if (!formField.zSchema?.options) return "Select field must have options defined. Use z.enum([...])"
    return (
      <Select onValueChange={field.onChange} defaultValue={field.value as string}>
        <SelectTrigger>
          <SelectValue placeholder={formField.placeholder || "Select..."} />
        </SelectTrigger>
        <SelectContent>
          {formField.zSchema.options.map((option) => (
            <SelectItem value={String(option)}>{option}</SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  }

  if (formField.type === "text" || formField.type === "time" || formField.type === "url") {
    return <Input {...field} type={formField.type} placeholder={formField.placeholder} />
  }

  if (formField.type === "number") {
    return <Input {...field} type={formField.type} placeholder={formField.placeholder} min={formField.min} />
  }
  if (formField.type === "textarea") return <Textarea {...field} placeholder={formField.placeholder} />
  if (formField.type === "email") return <Input {...field} type={formField.type} placeholder={formField.placeholder} />
  if (formField.type === "checkbox")
    return (
      <div className={"flex flex-row gap-2"}>
        <Checkbox checked={Boolean(field.value)} onCheckedChange={field.onChange} />
        <FormLabel>{formField.label}</FormLabel>
      </div>
    )

  if (formField.type === "checkbox_group") {
    // recurse through and enumerate options
    return (
      <div className={"flex flex-col gap-2 ml-2"}>
        {formField.options?.map((option) => (
          <div className={"flex flex-row gap-x-2"}>
            <Checkbox
              checked={(field.value as string[]).includes(option.value)}
              onCheckedChange={(checked) => {
                const currentValues = field.value as string[]

                if (checked) {
                  // @ts-expect-error - we know this is a string array
                  form.setValue(formField.name, [...currentValues, option.value])
                } else {
                  form.setValue(
                    formField.name,
                    // @ts-expect-error - we know this is a string array
                    currentValues.filter((v) => v !== option.value),
                  )
                }
              }}
            ></Checkbox>
            <FormLabel>{option.label}</FormLabel>
          </div>
        ))}
      </div>
    )
  }
  if (formField.type === "radio_group") {
    return (
      <div className={"flex flex-col gap-2 ml-2"}>
        <RadioGroup onValueChange={field.onChange} value={field.value as string}>
          {formField.options?.map((option) => (
            <FormItem>
              <FormControl>
                <RadioGroupItem value={option.value}></RadioGroupItem>
              </FormControl>
              <FormLabel>{option.label}</FormLabel>
            </FormItem>
          ))}
        </RadioGroup>
      </div>
    )
  }

  if (formField.type === "date") {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              variant={"outline"}
              className={cn("w-[240px] pl-3 text-left font-normal", !field.value && "text-muted-foreground")}
            >
              {field.value ? format(field.value as number, "PPP") : <span>Pick a date</span>}
              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={field.value as Date | undefined}
            onSelect={field.onChange as unknown as SelectSingleEventHandler}
            disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    )
  }

  return "This field type is not supported"
}

const DecoratedFormField = ({ field, formField, form }: IDecoratedFormField) => {
  return (
    <FormItem>
      {formField.type !== "checkbox" && <FormLabel>{formField.label}</FormLabel>}
      <FormControl>
        <DecoratedFormFieldContent field={field} formField={formField} form={form} />
      </FormControl>
      <FormMessage />
    </FormItem>
  )
}

export const zodFormSchemaBuilder = (formFields: FormField[]) => {
  return z.object(
    formFields.reduce(
      (schema, field) => {
        schema[field.name] = field.zSchema
        return schema
      },
      {} as Record<string, z.ZodTypeAny>,
    ),
  )
}

const FormFields = ({ formFields, form }: { formFields: FormField[]; form: PassedForm }) => {
  return formFields.map((formField) => (
    <FormField
      render={({ field }) => <DecoratedFormField formField={formField} field={field} form={form} />}
      name={formField.name}
      key={formField.name}
      control={form.control}
    />
  ))
}

const FormBuilderV2 = ({ formFields, onSubmit, defaultValues, children }: FormBuilderV2Props) => {
  const formSchema = zodFormSchemaBuilder(formFields)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  return (
    <Form {...form}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={form.handleSubmit(onSubmit)} className={"space-y-4"}>
        <FormFields formFields={formFields} form={form} />
        {children}
      </form>
    </Form>
  )
}

export default FormBuilderV2
