import SectionContainer from "../atom/SectionContainer"
import ActivityGraphSummaryCard from "./ActivityGraphSummaryCard"
import HomeTimeSummaryProvider from "./provider/HomeTimeSummaryProvider"
import SleepSummaryProvider from "./provider/SleepSummaryProvider"
import StepsSummaryProvider from "./provider/StepsSummaryProvider"
import useHomeTimeSummaryStore from "./store/HomeTimeSummaryStore"
import useSleepSummaryStore from "./store/SleepSummaryStore"
import useStepSummaryStore from "./store/StepSummaryStore"

const ActivitiesSummarySection: React.FC = () => {
  const currentStepsResponse = useStepSummaryStore((state) => state.currentStepsResponse)
  const stepsUnit = currentStepsResponse?.unit || ""
  const stepsValue = currentStepsResponse?.average_steps || "--"
  const stepsTrend = currentStepsResponse?.trend || "none"
  const stepsChartData = currentStepsResponse?.chart_data || []

  const currentSleepResponse = useSleepSummaryStore((state) => state.currentSleepResponse)
  const sleepUnit = currentSleepResponse?.unit || ""
  const sleepValue = currentSleepResponse?.average_sleep || "--"
  const sleepTrend = currentSleepResponse?.trend || "none"
  const sleepChartData = currentSleepResponse?.chart_data || []

  const currentHomeTimeResponse = useHomeTimeSummaryStore((state) => state.currentHomeTimeResponse)
  const homeTimeUnit = currentHomeTimeResponse?.unit || ""
  const homeTimeValue = currentHomeTimeResponse?.average_home_time || "--"
  const homeTimeTrend = currentHomeTimeResponse?.trend || "none"
  const homeTimeChartData = currentHomeTimeResponse?.chart_data || []

  return (
    <SectionContainer title="Activities" description="All stats represent average of available data over last 30 days">
      <div className="flex gap-3">
        <SleepSummaryProvider>
          <ActivityGraphSummaryCard
            label="Daily Sleep"
            value={sleepValue}
            unit={sleepUnit}
            trend={sleepTrend}
            chartData={sleepChartData}
            color="#5E89FF"
          />
        </SleepSummaryProvider>{" "}
        <StepsSummaryProvider>
          <ActivityGraphSummaryCard
            label="Daily Steps"
            value={stepsValue}
            unit={stepsUnit}
            trend={stepsTrend}
            chartData={stepsChartData}
            color="#01B85E"
          />
        </StepsSummaryProvider>
        <HomeTimeSummaryProvider>
          <ActivityGraphSummaryCard
            label="Home Time"
            value={homeTimeValue}
            unit={homeTimeUnit}
            trend={homeTimeTrend}
            chartData={homeTimeChartData}
            reverseTrend={true}
            color="#FFA500"
          />
        </HomeTimeSummaryProvider>
      </div>
    </SectionContainer>
  )
}

export default ActivitiesSummarySection
