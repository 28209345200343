import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons"

import SmallControlButton from "../../../../components/Buttons/SmallControlButton.tsx"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore.ts"
import useStepSummaryStore from "../summary/store/StepSummaryStore.ts"
import useActivityInsightStore from "./store/ActivityInsightStore.ts"

const ActivityRangeSelector = () => {
  const currentActivityOffsetDays = useActivityInsightStore((state) => state.currentOffsetDays)
  const setCurrentSleepOffsetDays = useSleepSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentStepsOffsetDays = useStepSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentActivityOffsetDays = useActivityInsightStore((state) => state.setCurrentOffsetDays)

  const dayRange = 30

  const getRangeStartText = () => {
    return `${dayRange + currentActivityOffsetDays}d ago`
  }

  const getRangeEndText = () => {
    if (currentActivityOffsetDays === 0) {
      return "Today"
    }
    return `${currentActivityOffsetDays}d ago`
  }

  const goToPrev = () => {
    setCurrentSleepOffsetDays(currentActivityOffsetDays + dayRange)
    setCurrentStepsOffsetDays(currentActivityOffsetDays + dayRange)
    setCurrentActivityOffsetDays(currentActivityOffsetDays + dayRange)
  }

  const goToNext = () => {
    if (currentActivityOffsetDays - dayRange < 0) {
      return
    }
    setCurrentSleepOffsetDays(currentActivityOffsetDays - dayRange)
    setCurrentStepsOffsetDays(currentActivityOffsetDays - dayRange)
    setCurrentActivityOffsetDays(currentActivityOffsetDays - dayRange)
  }

  return (
    <div className="flex items-center space-x-1">
      <div className="text-sm flex flex-row items-center font-medium border rounded-xl bg-white px-3 py-1 select-none">
        <SmallControlButton onClick={goToPrev} icon={faArrowLeftLong} direction="left" />
        <div className="min-w-36 items-center text-center">
          {getRangeStartText()}
          <span className="text-xs"> - </span>
          {getRangeEndText()}
        </div>
        <SmallControlButton onClick={goToNext} icon={faArrowRightLong} direction="right" />
      </div>
    </div>
  )
}

export default ActivityRangeSelector
