import { Row } from "@tanstack/react-table"
import { Dispatch, SetStateAction } from "react"

import { Button } from "@/shadcn-components/ui/button.tsx"
import { DialogFooter, DialogHeader, DialogTitle } from "@/shadcn-components/ui/dialog.tsx"

import useAddAssignmentStore from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsStore.ts"

import { success } from "@utilities/logger.ts"

interface Props {
  setPage: Dispatch<SetStateAction<number>>
  row: Row<ListUser>
  triggerProps: TriggerProps
}

const ConfirmAssignments = ({ setPage, row, triggerProps }: Props) => {
  const submitAssignmentsToAPI = useAddAssignmentStore((state) => state.submitAssignmentsToAPI)

  const handleSubmit = async () => {
    const user_id = row.original.user_id
    const response = await submitAssignmentsToAPI(user_id)
    if (response.message) success(response.message)

    triggerProps.onOpenChange(false)
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>Confirm Assignments</DialogTitle>
      </DialogHeader>
      <div>
        Based on the selections and rules you specified on the previous page, clicking "Assign" will immediately add
        these items to the user's feed.
      </div>
      <DialogFooter>
        <Button className={"bg-transparent !text-gray-500 border-gray-500 border"} onClick={() => setPage(1)}>
          Back
        </Button>
        <Button onClick={() => void handleSubmit()}>Assign</Button>
      </DialogFooter>
    </>
  )
}

export default ConfirmAssignments
