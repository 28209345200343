import SurveySection from "./SurveySection"
import useSurveysInsightStore from "./store/SurveysInsightStore"

const SurveyPage: React.FC = () => {
  const setCurrentOffsetDays = useSurveysInsightStore((state) => state.setCurrentOffsetDays)
  const setCurrentLimitDays = useSurveysInsightStore((state) => state.setCurrentLimitDays)

  setCurrentOffsetDays(0)
  setCurrentLimitDays(30)
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Survey</h3>
      </div>
      <SurveySection />
    </div>
  )
}

export default SurveyPage
