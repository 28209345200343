import useSurveysInsightStore from "@features/Insight/components/survey/store/SurveysInsightStore"

import HabitSection from "./HabitSection"

const HabitPage: React.FC = () => {
  const setCurrentOffsetDays = useSurveysInsightStore((state) => state.setCurrentOffsetDays)
  const setCurrentLimitDays = useSurveysInsightStore((state) => state.setCurrentLimitDays)

  setCurrentOffsetDays(0)
  setCurrentLimitDays(30)

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Habit</h3>
      </div>

      <HabitSection />
    </div>
  )
}

export default HabitPage
