import { faArrowLeftLong, faArrowRightLong, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"

import SmallControlButton from "../../../../components/Buttons/SmallControlButton.tsx"
import useSurveysInsightStore from "./store/SurveysInsightStore.ts"

interface SurveyRangeSelectorProps {
  allowRangeControl?: boolean
}

const SurveyRangeSelector: React.FC<SurveyRangeSelectorProps> = ({ allowRangeControl = false }) => {
  const currentOffsetDays = useSurveysInsightStore((state) => state.currentOffsetDays)
  const currentLimitDays = useSurveysInsightStore((state) => state.currentLimitDays)
  const setCurrentLimitDays = useSurveysInsightStore((state) => state.setCurrentLimitDays)
  const setCurrentOffsetDays = useSurveysInsightStore((state) => state.setCurrentOffsetDays)

  const ranges = [30, 60, 120]
  const currentRangeIndex = ranges.indexOf(currentLimitDays)

  const getRangeStartText = () => {
    return `${currentLimitDays + currentOffsetDays}d ago`
  }

  const getRangeEndText = () => {
    if (currentOffsetDays === 0) {
      return "Today"
    }
    return `${currentOffsetDays}d ago`
  }

  const increaseRange = () => {
    if (currentRangeIndex < ranges.length - 1) {
      setCurrentLimitDays(ranges[currentRangeIndex + 1])
    }
  }

  const decreaseRange = () => {
    if (currentRangeIndex > 0) {
      setCurrentLimitDays(ranges[currentRangeIndex - 1])
    }
  }

  const goToPrev = () => {
    setCurrentOffsetDays(currentOffsetDays + currentLimitDays)
  }

  const goToNext = () => {
    setCurrentOffsetDays(Math.max(0, currentOffsetDays - currentLimitDays))
  }

  return (
    <div className="flex items-center space-x-1">
      {allowRangeControl && (
        <div className="flex flex-row rounded-xl bg-white border px-3 py-1 text-sm font-medium">
          <SmallControlButton onClick={increaseRange} icon={faPlus} direction="left" />
          <div className="min-w-20 items-center text-center">{currentLimitDays} days</div>
          <SmallControlButton onClick={decreaseRange} icon={faMinus} direction="right" />
        </div>
      )}

      <div className="flex flex-row text-sm items-center font-medium border rounded-xl bg-white px-3 py-1">
        <SmallControlButton onClick={goToPrev} icon={faArrowLeftLong} direction="left" />
        <div className="min-w-32 items-center text-center transtion ">
          {getRangeStartText()}
          <span className="text-xs"> - </span>
          {getRangeEndText()}
        </div>
        <SmallControlButton onClick={goToNext} icon={faArrowRightLong} direction="right" />
      </div>
    </div>
  )
}

export default SurveyRangeSelector
