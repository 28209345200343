import { Button } from "@/shadcn-components/ui/button.tsx"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/shadcn-components/ui/dialog.tsx"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

const RevokeRelationshipDialog = ({ row, triggerProps }: DropdownTriggeredDialogProps<ListUser>) => {
  const user = row.original
  const revokeRel = async (user_id: string) => {
    try {
      const response = await api.delete<{ error: string }>(`/relationships/clinician_revoke/${user_id}`)
      if (response.status === 200) {
        success("Relationship Revoked Successfully")
        triggerProps.onOpenChange(false)
      } else {
        error(response.data.error, true)
      }
    } catch (err) {
      error((err as Error).message, true)
    }
  }

  if (triggerProps?.open)
    return (
      <Dialog {...triggerProps}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Disconnect from Client</DialogTitle>
            <DialogDescription>
              Are you sure you'd like to disconnect from this client? If you change your mind, you will need to ask the
              client to re-add you as their clinician.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant={"outline"} onClick={() => (triggerProps.onOpenChange as () => void)()}>
              {" "}
              Cancel
            </Button>
            <Button onClick={() => void revokeRel(user.user_id)}>Confirm Disconnect</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
}

export default RevokeRelationshipDialog
