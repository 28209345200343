import Card from "@/components/Cards/Card.tsx"

import TrendIcon from "../atom/TrendIcon"
import SurveyScoresGraph from "../graph/SurveyScoresGraph.tsx"

interface SurveyScoresProps {
  selectedSurveyScores: SurveyScoresDetailsItem | null
  cardStyle: string
}

const SurveyScores: React.FC<SurveyScoresProps> = ({ selectedSurveyScores, cardStyle }) => {
  if (!selectedSurveyScores) {
    return
  }

  const getColorStyle = (score: number) => {
    if (score <= selectedSurveyScores.medium) {
      return "text-green-500"
    } else if (score <= selectedSurveyScores.high) {
      return "text-yellow-400"
    } else {
      return "text-red-500"
    }
  }
  const currentScore = selectedSurveyScores.scores[selectedSurveyScores.scores.length - 1].score

  return (
    <div className="flex gap-3 w-full">
      <Card additionalClasses={cardStyle}>
        <div className="text-xl">Survey Scores</div>
        <div className="flex justify-between items-center">
          <div>
            Trend <TrendIcon trend={selectedSurveyScores.trend} reverseColor={true} />
            <span className={"text-xs text-neutral-300"}> compared to the last survey entry</span>
          </div>
          <div>
            Latest Score <span className={`${getColorStyle(currentScore)}`}>{currentScore}</span>
          </div>
        </div>
        <SurveyScoresGraph graphData={selectedSurveyScores} />
      </Card>
    </div>
  )
}

export default SurveyScores
