import DiskLabelImage from "@assets/images/color-disk-insight-label.svg"
import DiskSideLabelImage from "@assets/images/color-disk-side-label.svg"
import * as d3 from "d3"
import { slice } from "lodash"

class DensityGraphAnimatedD3 {
  constructor(element, width, height, dataSets, duration = 3000, autoPlay = false, startAt = 0, showControls = true) {
    this.element = element
    this.width = width
    this.height = height
    this.dataSets = this.renderData(dataSets)
    this.currentIndex = startAt % this.dataSets.length
    this.duration = duration
    this.margin = { top: 30, right: 30, bottom: 30, left: 30 }
    this.intervalId = null
    this.createChart()
    if (showControls) {
      this.addControls()
    }
    if (autoPlay) {
      this.autoPlay()
    }

    // this.addGuidelines()
  }
  renderData(moodData) {
    const result = []
    const days = Object.keys(moodData).sort()
    const groupSize = 7
    let currentGroup = []

    const polarToCartesian = (angle, intensity) => {
      const radians = ((angle - 90) * Math.PI) / 180
      const x = 5 + intensity * 5 * Math.cos(radians)
      const y = 5 - intensity * 5 * Math.sin(radians)
      return { x, y }
    }

    let dayAdded = 0

    for (const day of days) {
      const moods = moodData[day]

      if (moods.length === 0) {
        currentGroup.push({
          x: null,
          y: null,
          date: day,
        })
      } else {
        moods.forEach((mood) => {
          const { x, y } = polarToCartesian(mood.angle, mood.intensity)
          currentGroup.push({
            x,
            y,
            date: day,
          })
        })
      }
      dayAdded += 1

      if (dayAdded >= groupSize) {
        result.push(currentGroup)
        currentGroup = []
        dayAdded = 0
      }
    }

    if (currentGroup.length > 0) {
      result.push(currentGroup)
    }

    return result
  }

  createChart() {
    // Remove any existing chart
    d3.select(this.element).select("svg").remove()

    this.svg = d3
      .select(this.element)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .append("g")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom
    const innerBandWidth = innerWidth / 10 / 2
    this.centerX = innerWidth / 2
    this.centerY = innerHeight / 2

    // Set up X and Y scales
    const marging = 30
    this.x = d3
      .scaleLinear()
      .domain([0, 10])
      .range([this.centerX - innerWidth / 2, this.centerX + innerWidth / 2])

    this.y = d3
      .scaleLinear()
      .domain([0, 10])
      .range([this.centerY + innerHeight / 2, this.centerY - innerHeight / 2])

    // Add intensity scale circles
    for (let i = 0; i < 10; i++) {
      this.svg
        .append("circle")
        .attr("cx", this.centerX)
        .attr("cy", this.centerY)
        .attr("r", innerWidth / 2 - i * innerBandWidth)
        .attr("stroke", "black")
        .attr("stroke-width", 0.5)
        .attr("fill", "none")
        .attr("opacity", 0.2 - i * 0.02)
    }

    // Add mood categories divider lines
    const innerRadius = innerWidth / 2 - 9 * innerBandWidth
    for (let i = 0; i < 12; i++) {
      const angle = (i * Math.PI) / 6
      const x1 = this.centerX + innerRadius * Math.cos(angle)
      const y1 = this.centerY + innerRadius * Math.sin(angle)
      const x2 = this.centerX + (innerWidth / 2) * Math.cos(angle)
      const y2 = this.centerY + (innerWidth / 2) * Math.sin(angle)

      this.svg
        .append("line")
        .attr("x1", x1)
        .attr("y1", y1)
        .attr("x2", x2)
        .attr("y2", y2)
        .attr("stroke", "black")
        .attr("stroke-dasharray", "2,2")
        .attr("opacity", 0.3)
        .attr("stroke-width", 0.5)
    }

    // Add labels one the 4 sides
    this.svg
      .append("image")
      .attr("href", DiskSideLabelImage)
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("opacity", 0.5)
      .attr("x", this.centerX - this.width / 2)
      .attr("y", this.centerY - this.height / 2)

    // Initialize the heatmap with the first data set
    this.updateDensityData(this.dataSets[this.currentIndex])
  }

  addControls() {
    this.svg.selectAll(".controls").remove()
    const controlGroup = this.svg.append("g").attr("class", "controls")

    this.addButton(controlGroup, 20, this.height - 45, "⏸", () => this.stopAutoPlay())
    this.addButton(controlGroup, -10, this.height - 45, ">", () => this.continueAutoPlay())
    this.addButton(controlGroup, this.width - 80, this.height - 45, "←", () => this.showPreviousData())
    this.addButton(controlGroup, this.width - 50, this.height - 45, "→", () => this.showNextData())

    controlGroup.raise()
  }

  addGuidelines() {
    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    // Draw vertical and horizontal center lines
    this.svg
      .append("line")
      .attr("x1", this.centerX)
      .attr("y1", 0)
      .attr("x2", this.centerX)
      .attr("y2", innerHeight)
      .attr("stroke", "#000")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "4,4")

    this.svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", this.centerY)
      .attr("x2", innerWidth)
      .attr("y2", this.centerY)
      .attr("stroke", "#000")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "4,4")

    // Indicate the innerWidth and innerHeight with small text at the corners
    this.svg
      .append("text")
      .attr("x", innerWidth - 10)
      .attr("y", -5)
      .attr("text-anchor", "end")
      .style("font-size", "10px")
      .style("fill", "#888")
      .text(`Width: ${innerWidth}`)

    this.svg
      .append("text")
      .attr("x", 5)
      .attr("y", innerHeight - 5)
      .attr("text-anchor", "start")
      .style("font-size", "10px")
      .style("fill", "#888")
      .text(`Height: ${innerHeight}`)

    // Add small circles at the 4 corners of the inner area
    const corners = [
      [0, 0],
      [innerWidth, 0],
      [0, innerHeight],
      [innerWidth, innerHeight],
    ]

    corners.forEach(([x, y]) => {
      this.svg.append("circle").attr("cx", x).attr("cy", y).attr("r", 3).attr("fill", "#f00")
    })
  }

  addButton(group, x, y, text, onClick) {
    const buttonWidth = 25
    const buttonHeight = 25
    const buttonPadding = 10

    // Add the rectangle behind the text
    group
      .append("rect")
      .attr("x", x - buttonWidth / 2)
      .attr("y", y - buttonHeight / 2)
      .attr("width", buttonWidth)
      .attr("height", buttonHeight)
      .attr("rx", 10)
      .attr("ry", 10)
      .attr("fill", "#ffffff")
      .attr("stroke", "#cccccc")
      .attr("stroke-width", 0.5)
      .style("cursor", "pointer")
      .on("mouseover", function () {
        d3.select(this).attr("fill", "#cccccc")
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", "#ffffff")
      })
      .on("click", onClick)

    // Add the text element
    group
      .append("text")
      .attr("x", x)
      .attr("y", y + buttonPadding / 2)
      .attr("text-anchor", "middle")
      .text(text)
      .style("cursor", "pointer")
      .style("fill", "#000000")
      .style("font-size", "14px")
      .attr("pointer-events", "none")
      .attr("user-select", "none")
      .style("-webkit-user-select", "none")
      .style("-moz-user-select", "none")
      .style("-ms-user-select", "none")
  }

  stopAutoPlay() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  continueAutoPlay() {
    this.autoPlay()
  }

  addLabels() {
    // remove existing labels
    this.svg.selectAll("#disk-label").remove()
    // Add DiskLabelImage
    this.svg
      .append("image")
      .attr("href", DiskLabelImage)
      .attr("id", "disk-label")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("opacity", 0.8)
      .attr("x", this.centerX - this.width / 2)
      .attr("y", this.centerY - this.height / 2)
      .attr("pointer-events", "none")

      .raise()
  }

  showPreviousData() {
    this.stopAutoPlay()
    this.currentIndex = (this.currentIndex - 1 + this.dataSets.length) % this.dataSets.length
    this.updateDensityData(this.dataSets[this.currentIndex])
  }

  showNextData() {
    this.stopAutoPlay()
    this.currentIndex = (this.currentIndex + 1) % this.dataSets.length
    this.updateDensityData(this.dataSets[this.currentIndex])
  }

  updateDensityData(currentData) {
    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom
    const easingDuration = 500

    // Compute the density data with reduced thresholds
    const densityData = d3
      .contourDensity()
      .x((d) => this.x(d.x))
      .y((d) => this.y(d.y))
      .size([innerWidth, innerHeight])
      .bandwidth(12)
      .thresholds(10)(currentData)

    // Calculate the extent of the density values for color scaling
    const densityExtent = d3.extent(densityData, (d) => d.value)

    // Update the color scale based on the new density extent
    this.color = d3.scaleLinear().domain(densityExtent).range(["#76ffbc", "#395dff"])

    // Bind data and create density paths with a unique key using id (date)
    const paths = this.svg.selectAll("path.heatmap").data(densityData, (d) => `${d.date}`)

    // Fade out and remove old paths with a longer transition and ensure removal happens after animation
    paths.exit().transition().duration(300).attr("fill-opacity", 0).remove()

    // Update existing paths: set opacity to 0, then fade in
    paths
      .attr("fill-opacity", 0)
      .attr("d", d3.geoPath())
      .attr("fill", (d) => this.color(d.value))
      .transition()
      .duration(easingDuration)
      .attr("fill-opacity", 0.4)
      .attr("pointer-events", "none")

    // Enter new paths after old paths have faded out and been removed
    paths
      .enter()
      .append("path")
      .attr("class", "heatmap")
      .attr("d", d3.geoPath())
      .attr("fill", (d) => this.color(d.value))
      .attr("fill-opacity", 0)
      .attr("stroke", "none")
      .attr("pointer-events", "none")
      .transition()
      .duration(easingDuration)
      .attr("fill-opacity", 0.4)

    // Add or update the date range display
    const dateRange = d3.extent(currentData, (d) => d.date)

    const formattedDateRange = dateRange.map((date) =>
      new Date(`${date}T00:00:00`).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      }),
    )

    const dateText = this.svg.selectAll(".date-range-text")

    if (dateText.empty()) {
      this.svg
        .append("text")
        .attr("class", "date-range-text")
        .attr("x", -25)
        .attr("y", -20)
        .attr("text-anchor", "start")
        .style("font-size", "11px")
        .style("fill", "#333")
        .text(`${formattedDateRange[0]} - ${formattedDateRange[1]}`)
    } else {
      // Update the existing text
      dateText.text(`${formattedDateRange[0]} - ${formattedDateRange[1]}`)
    }

    this.addLabels()
  }

  autoPlay() {
    if (this.intervalId) return
    if (this.dataSets.length > 1) {
      this.intervalId = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.dataSets.length
        this.updateDensityData(this.dataSets[this.currentIndex])
      }, this.duration)
    } else {
      this.updateDensityData(this.dataSets[0])
    }
  }
}

export default DensityGraphAnimatedD3
