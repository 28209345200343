import useActivityInsightStore from "./store/ActivityInsightStore"

interface TabButtonProps {
  isActive: boolean
  label: ActivityGraphType
  onClick: (label: ActivityGraphType) => void
}

const TabButton: React.FC<TabButtonProps> = ({ isActive, label, onClick }) => {
  const baseClasses = "flex items-center justify-center font-light h-full select-none"
  const activeClasses = "text-white bg-black"
  const inactiveClasses = "hover:bg-neutral-500 hover:text-white"
  const roundedClasses = "rounded-full"
  const transitionClasses = "transition-colors duration-300 ease-in-out"

  const className = `${baseClasses} ${isActive ? activeClasses : inactiveClasses} ${roundedClasses} ${transitionClasses}`

  return (
    <button className={className} onClick={() => onClick(label)}>
      {label}
    </button>
  )
}

const SleepAndStepsGraphTabs: React.FC = () => {
  const { currentGraphFormat, setCurrentGraphFormat } = useActivityInsightStore()
  const tabs: ActivityGraphType[] = ["Timeline", "Sleep Aggregate"]
  const handleClick = (tab: ActivityGraphType) => {
    setCurrentGraphFormat(tab)
  }

  return (
    <div className="w-96 flex rounded-full h-12 grid grid-cols-2 p-2 mt-5 -mb-3 text-center bg-neutral-50 text-neutral-400 text-sm justify-center gap-3">
      {tabs.map((tab) => (
        <TabButton key={tab} isActive={currentGraphFormat === tab} label={tab} onClick={handleClick} />
      ))}
    </div>
  )
}

export default SleepAndStepsGraphTabs
