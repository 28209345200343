import { Row } from "@tanstack/react-table"

import UsersTable from "@components/Widgets/UsersTable/UsersTable.tsx"
import { UsersTableColumns } from "@components/Widgets/UsersTable/UsersTableColumns.tsx"
import UsersTableDropdownMenu from "@components/Widgets/UsersTable/UsersTableDropdownMenu.tsx"

const ManageUsers = () => {
  return (
    <UsersTable
      columns={[
        ...UsersTableColumns,
        {
          id: "actions",
          cell: ({ row }: { row: Row<ListUser> }) => <UsersTableDropdownMenu row={row} useCase={"admin"} />,
        },
      ]}
    />
  )
}

export default ManageUsers
