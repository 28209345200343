import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"
import SurveysSummaryContent from "./SurveysSummaryContent"
import SurveysSummaryProvider from "./provider/SurveysSummaryProvider"
import useSurveySummaryStore from "./store/SurveySummaryStore"

const SurveysSummarySection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"
  const currentLimitDays = useSurveySummaryStore((state) => state.currentLimitDays)

  return (
    <SectionContainer title="Surveys" description={`Latest survey scores for the past ${currentLimitDays} days`}>
      <div className="flex gap-3">
        <Card additionalClasses={cardStyle}>
          <SurveysSummaryProvider>
            <SurveysSummaryContent />
          </SurveysSummaryProvider>
        </Card>
      </div>
    </SectionContainer>
  )
}

export default SurveysSummarySection
