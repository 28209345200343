import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"

import LoadingSpinner from "@/components/Loading/LoadingSpinner.tsx"
import useAuthStore from "@/core/Authentication/stores/AuthStore.ts"
import api from "@/utilities/api.ts"

import useClinicianRelationshipsStore from "@features/Home/stores/ClinicanRelationshipsStore.ts"

const ClinicianRelationshipsProvider = ({ children, clientUser }: { children: ReactNode; clientUser: ListUser }) => {
  const user = useAuthStore((state) => state.user)
  const setClinicianRelationships = useClinicianRelationshipsStore((state) => state.setClinicianRelationships)
  const setClientPolicies = useClinicianRelationshipsStore((state) => state.setClientPolicies)

  const {
    data: relationshipData,
    isPending: relationshipIsPending,
    isError: relationshipIsError,
  } = useQuery({
    queryKey: ["clinicianRelationships", user.id],
    enabled: !!user.id,
    queryFn: async () => {
      const response = await api.get<Relationship[]>("/relationships/self?relationships=policies,provider_profile")
      if (response.status == 200) return response.data
      else throw new Error(response.statusText)
    },
  })

  const {
    data: clientPolicyData,
    isPending: clientPolicyIsPending,
    isError: clientPolicyIsError,
  } = useQuery({
    queryKey: ["clientPolicies", clientUser.user_id],
    enabled: !!user.id || !!clientUser.user_id,
    queryFn: async () => {
      const response = await api.get<{ data: PolicyItem[]; status: number; error: string }>(
        `/relationship_policies/clinician/${clientUser.user_id}`,
      )
      if (response.status == 200) return response.data.data
      else throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (clientPolicyData) {
      setClientPolicies(clientPolicyData)
    }
  }, [clientPolicyData, setClientPolicies])

  useEffect(() => {
    if (relationshipData) {
      setClinicianRelationships(relationshipData)
    }
  }, [relationshipData, setClinicianRelationships])

  const isPending = relationshipIsPending || clientPolicyIsPending
  const isError = relationshipIsError || clientPolicyIsError

  if (isPending) return <LoadingSpinner fullScreen />
  if (isError) return

  return children
}
export default ClinicianRelationshipsProvider
