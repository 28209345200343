import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useSleepSummaryStore from "../../summary/store/SleepSummaryStore.ts"

const DailySleepActivityProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentSleepData = useSleepSummaryStore((state) => state.setCurrentSleepResponse)
  const currentOffsetDays = useSleepSummaryStore((state) => state.currentOffsetDays)
  const currentLimitDays = useSleepSummaryStore((state) => state.currentLimitDays)
  const { clientId } = useParams()

  const {
    data: sleepDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["sailySleepActivityData", clientId, currentOffsetDays, currentLimitDays],
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/summary/sleep/${clientId}?offset_days=${currentOffsetDays}&limit_days=${currentLimitDays}`
      const { data, status } = await api.get<SleepSummaryResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching Sleep data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && sleepDataResponse) {
      setCurrentSleepData(sleepDataResponse)
    }
  }, [sleepDataResponse, isSuccess, setCurrentSleepData])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError)
    return (
      <div className="my-6 w-full text-center text-sm text-neutral-200">Cound not load sleep activity at this time</div>
    )

  return children
}

export default DailySleepActivityProvider
