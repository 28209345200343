import { axisBottom, axisRight, scaleBand, scaleTime, select, timeFormat } from "d3"

export default class SleepStateBarChartD3 {
  constructor(element, width, height, data) {
    // Initialize chart properties
    this.margin = { top: 10, left: 20, bottom: 30, right: 50 }
    this.width = width
    this.height = height
    this.chartHeight = this.height - this.margin.top - this.margin.bottom
    this.chartWidth = this.width - this.margin.left - this.margin.right
    this.labels = ["In Bed", "Asleep", "Awake", "Core", "Deep", "REM"]
    this.colors = ["#b7d3ff", "#09de77", "#ffa195", "#395dff", "#5e89ff", "#8bb5ff"]

    // Clear previous SVG elements
    select(element).selectAll("*").remove()

    // Create tooltip element
    this.tooltip = select(element)
      .append("div")
      .attr("class", "sleep-tooltip")
      .style("position", "absolute")
      .style("padding-left", "12px")
      .style("padding-right", "12px")
      .style("padding-top", "4px")
      .style("padding-bottom", "4px")
      .style("background", "#000")
      .style("border-radius", "20px")
      .style("pointer-events", "none")
      .style("color", "#fff")
      .style("opacity", 0)

    // Create the SVG container
    this.svg = select(element)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

    // Deduplicate data
    const deduplicatedData = this.deduplicateData(data)

    // Process data into days, filling gaps for missing days
    const dayData = this.processDataByDay(deduplicatedData)

    // Determine the dynamic y-domain based on data coverage
    const [yDomainStart, yDomainEnd] = this.computeYDomain(dayData)

    // Create scales
    this.x = this.xScale(dayData)
    this.y = this.yScale(yDomainStart, yDomainEnd)

    // Add axes
    this.addAxes(dayData)

    // Add rectangles
    this.addRectangles(dayData)
  }

  deduplicateData(data) {
    const seen = new Set()
    return data.filter((item) => {
      const data_str = item.sample_start + "-" + item.sample_end + "-" + item.sample_value
      const duplicate = seen.has(data_str)
      seen.add(data_str)
      return !duplicate
    })
  }

  normalizeDate(d) {
    return new Date(d)
  }

  getDayForDate(date) {
    const dt = new Date(date)
    const dayStr = dt.toISOString().split("T")[0] // YYYY-MM-DD
    const year = dt.getUTCFullYear()
    const month = dt.getUTCMonth()
    const day = dt.getUTCDate()

    const sixPm = new Date(Date.UTC(year, month, day, 18, 0, 0))
    if (dt >= sixPm) {
      const nextDay = new Date(Date.UTC(year, month, day + 1, 0, 0, 0))
      const nextDayStr = nextDay.toISOString().split("T")[0]
      return nextDayStr
    } else {
      return dayStr
    }
  }

  // Process data into buckets by day and fill in missing days
  processDataByDay(data) {
    const grouped = {}
    data.forEach((d) => {
      const start = this.normalizeDate(d.sample_start)
      const end = this.normalizeDate(d.sample_end)
      const dayKey = this.getDayForDate(start)

      if (!grouped[dayKey]) {
        grouped[dayKey] = []
      }
      grouped[dayKey].push({
        ...d,
        start,
        end,
      })
    })

    // Find the min and max day
    const days = Object.keys(grouped).sort()
    if (days.length === 0) {
      return []
    }

    const minDay = days[0]
    const maxDay = days[days.length - 1]

    // Fill in missing days
    const allDays = this.fillMissingDays(minDay, maxDay, grouped)

    // Convert to array of {day: 'YYYY-MM-DD', values: [...]}
    return allDays.map((day) => {
      const vals = grouped[day] || []
      return {
        day,
        values: vals.sort((a, b) => a.start - b.start),
      }
    })
  }

  // Given a start and end day string (YYYY-MM-DD), fill in all days in between
  fillMissingDays(minDay, maxDay, grouped) {
    const startDate = new Date(minDay)
    const endDate = new Date(maxDay)

    const dayList = []
    for (let d = new Date(startDate.getTime()); d <= endDate; d.setDate(d.getDate() + 1)) {
      const dayStr = d.toISOString().split("T")[0]
      dayList.push(dayStr)
      if (!grouped[dayStr]) {
        grouped[dayStr] = []
      }
    }
    return dayList
  }

  // Convert actual date to reference time offset from a fictional reference day 6pm

  convertToReferenceTime(date) {
    const year = date.getUTCFullYear()
    const month = date.getUTCMonth()
    const day = date.getUTCDate()

    const daySixPm = new Date(Date.UTC(year, month, day, 18, 0, 0))
    if (date < daySixPm) {
      const prevDaySixPm = new Date(daySixPm.getTime() - 24 * 60 * 60 * 1000)
      return new Date(Date.UTC(2024, 0, 1, 18, 0, 0) + (date - prevDaySixPm))
    } else {
      return new Date(Date.UTC(2024, 0, 1, 18, 0, 0) + (date - daySixPm))
    }
  }

  // Compute the dynamic y-domain based on actual data coverage
  computeYDomain(dayData) {
    // If no data at all
    if (!dayData || dayData.length === 0) {
      const referenceDay = new Date(Date.UTC(2024, 0, 1, 18, 0, 0))
      const nextDay = new Date(referenceDay.getTime() + 24 * 60 * 60 * 1000)
      return [referenceDay, nextDay]
    }

    let globalMin = null
    let globalMax = null

    for (const dayObj of dayData) {
      for (const d of dayObj.values) {
        const startRef = this.convertToReferenceTime(d.start)
        const endRef = this.convertToReferenceTime(d.end)

        if (globalMin === null || startRef < globalMin) {
          globalMin = startRef
        }
        if (globalMax === null || endRef > globalMax) {
          globalMax = endRef
        }
      }
    }

    const referenceDay = new Date(Date.UTC(2024, 0, 1, 18, 0, 0))
    const fullDayHours = 24 * 60 * 60 * 1000
    // If no actual segments found, default to full day
    if (!globalMin || !globalMax) {
      const nextDay = new Date(referenceDay.getTime() + fullDayHours)
      return [referenceDay, nextDay]
    }

    const dataRange = globalMax - globalMin

    // If data does not span full 24 hours, shrink domain
    if (dataRange < fullDayHours) {
      return [globalMin, globalMax]
    } else {
      // Show full 24 hours from 6pm to next 6pm
      const nextDay = new Date(referenceDay.getTime() + fullDayHours)
      return [referenceDay, nextDay]
    }
  }

  // x-scale: a band scale for each day
  xScale(dayData) {
    return scaleBand()
      .domain(dayData.map((d) => d.day))
      .range([0, this.chartWidth])
      .padding(0.3)
  }

  // y-scale: from the computed domain
  yScale(yDomainStart, yDomainEnd) {
    return scaleTime().domain([yDomainStart, yDomainEnd]).range([0, this.chartHeight])
  }

  addAxes(dayData) {
    // Map for day-of-week abbreviations
    const daysMap = {
      Sun: "S",
      Mon: "M",
      Tue: "T",
      Wed: "W",
      Thu: "T",
      Fri: "F",
      Sat: "S",
    }

    // Add X-axis
    const xAxis = axisBottom(this.x).tickFormat((d) => {
      const parts = d.split("-")
      const dt = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]))
      const dayOfWeek = timeFormat("%a")(dt)
      return daysMap[dayOfWeek] || dayOfWeek
    })

    this.svg.append("g").attr("transform", `translate(0, ${this.chartHeight})`).call(xAxis).style("color", "#888888")

    // Add X-axis grid lines
    this.svg
      .append("g")
      .attr("class", "x-grid")
      .attr("transform", `translate(0, ${this.chartHeight})`)
      .call(axisBottom(this.x).tickSize(-this.chartHeight).tickFormat(""))
      .selectAll("line")
      .style("stroke", "#e0e0e0")
      .style("stroke-dasharray", "2,2")

    // Add Y-axis
    const yAxis = axisRight(this.y)
      .ticks(6)
      .tickFormat((d) => timeFormat("%-I%p")(d))

    this.svg
      .append("g")
      .attr("transform", "translate(" + this.chartWidth + ",0)")
      .call(yAxis)
      .style("color", "#888888")

    // Add Y-axis grid lines
    this.svg
      .append("g")
      .attr("class", "y-grid")
      .call(axisRight(this.y).ticks(6).tickSize(this.chartWidth).tickFormat(""))
      .selectAll("line")
      .style("stroke", "#e0e0e0")

    this.svg.selectAll(".domain").remove()
  }

  addRectangles(dayData) {
    dayData.forEach((dayObj) => {
      const day = dayObj.day
      const values = dayObj.values
      const dayX = this.x(day)

      this.svg
        .selectAll(`.rect-${day}`)
        .data(values)
        .enter()
        .append("rect")
        .attr("class", `rect-${day}`)
        .attr("x", dayX)
        .attr("width", this.x.bandwidth())
        .attr("y", (d) => this.y(this.convertToReferenceTime(d.start)))
        .attr(
          "height",
          (d) => this.y(this.convertToReferenceTime(d.end)) - this.y(this.convertToReferenceTime(d.start)),
        )
        .attr("fill", (d) => this.colors[d.sample_value])
        .on("mouseover", (event, d) => this.showTooltip(event, d))
        .on("mousemove", (event) => this.moveTooltip(event))
        .on("mouseout", () => this.hideTooltip())
    })
  }

  formatTime(date) {
    return timeFormat("%-I:%M %p")(date)
  }

  showTooltip(event, d) {
    const startDate = new Date(d.start)
    const endDate = new Date(d.end)
    const tooltipAccentColor = "#8BB5FF"
    const startTimeLabel = this.formatTime(startDate)
    const endTimeLabel = this.formatTime(endDate)
    const timeLabel = `${startTimeLabel} - ${endTimeLabel}`
    const value = this.labels[d.sample_value]

    this.tooltip
      .html(
        `
          <span style="font-size: 12px; color: ${tooltipAccentColor};">${timeLabel}</span>
          <span>${value}</span>
        `,
      )
      .style("white-space", "nowrap")
      .transition()
      .duration(300)
      .style("opacity", 1)
  }

  moveTooltip(event) {
    const tooltipWidth = this.tooltip.node().offsetWidth

    this.tooltip
      .attr("anchor", "middle")
      .style("left", `${event.pageX - tooltipWidth / 2}px`)
      .style("top", `${event.pageY - 40}px`)
  }

  hideTooltip() {
    this.tooltip.transition().duration(300).style("opacity", 0)
  }
}
