import { permissionsMap } from "@/data/permissions.const.ts"

import ToggleSwitchWithDescription from "@components/Forms/Checkboxes/ToggleSwitchWithDescription.tsx"

import useClinicianRelationshipsStore from "@features/Home/stores/ClinicanRelationshipsStore.ts"

const ClinicianInspectPolicies = () => {
  const clientPolicies = useClinicianRelationshipsStore((state) => state.clientPolicies)

  return (
    <>
      {clientPolicies
        ?.sort((a, b) => (a.permission_key > b.permission_key ? 1 : -1))
        .map((policy) => {
          const { title = policy.permission_key, description = "A description has not been provided." } =
            permissionsMap?.[policy.permission_key] ?? {}
          return (
            <div key={policy.policy_id}>
              <ToggleSwitchWithDescription
                checked={policy.enabled}
                title={title}
                description={description}
                onChange={() => {}} // eslint-disable-line
              />
            </div>
          )
        })}
      {clientPolicies?.length === 0 && (
        <p className={"border border-black-400 p-4 text-sm rounded"}>
          You are either not directly connected to this user or they have not set any permissions.
        </p>
      )}
    </>
  )
}

export default ClinicianInspectPolicies
