import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useActivityInsightStore from "../store/ActivityInsightStore.ts"

const ActivityStackGraphProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentActivityResponse = useActivityInsightStore((state) => state.setCurrentActivityResponse)
  const currentOffsetDays = useActivityInsightStore((state) => state.currentOffsetDays)
  const currentLimitDays = useActivityInsightStore((state) => state.currentLimitDays)
  const { clientId } = useParams()

  const {
    data: activityDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["activityStackGraphData", clientId, currentOffsetDays, currentLimitDays],
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/activity/${clientId}?offset_days=${currentOffsetDays}&limit_days=${currentLimitDays}`
      const { data, status } = await api.get<{ activity: ActivityStackResponse }>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching Activity data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && activityDataResponse) {
      setCurrentActivityResponse(activityDataResponse.activity)
    }
  }, [activityDataResponse, isSuccess, setCurrentActivityResponse])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError)
    return (
      <div className="my-6 w-full text-center text-sm text-neutral-200">
        Cound not load sleep & steps graph at this time
      </div>
    )

  return children
}

export default ActivityStackGraphProvider
