import { Row } from "@tanstack/react-table"

import { DropdownMenuItem, DropdownMenuSeparator } from "@/shadcn-components/ui/dropdown-menu.tsx"

import UsersTableDropdownMenu from "@components/Widgets/UsersTable/UsersTableDropdownMenu.tsx"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

interface Props {
  data: ListUser
  row: Row<ListUser>
  groupId: string
}

const GroupMemberDropdown = ({ data, row, groupId }: Props) => {
  const deleteUserFromGroup = async () => {
    const response = await api.delete<{ message: string }>(`/groups/${groupId}/members/${data?.user_id}`)
    if (response.status === 200) {
      if (data?.refetch) data.refetch()
      success(response.data.message)
    } else {
      error(response.data.message)
    }
  }

  const makeUserAdmin = async () => {
    const response = await api.put<{
      message: string
    }>(`/groups/${groupId}/members/${data?.user_id}`, { roles: ["admin"] })
    if (response.status === 200) {
      if (data?.refetch) data.refetch()
      success(response.data.message)
    } else {
      error(response.data.message)
    }
  }

  const customButtons: DropdownMenuButton[] = [
    {
      useCases: "all",
      component: <DropdownMenuSeparator />,
    },
    {
      useCases: "all",
      component: <DropdownMenuItem onClick={() => void makeUserAdmin()}>Make admin</DropdownMenuItem>,
    },
    {
      useCases: "all",
      component: <DropdownMenuItem onClick={() => void deleteUserFromGroup()}>Remove from group</DropdownMenuItem>,
    },
  ]

  return <UsersTableDropdownMenu row={row} useCase={"group_members"} additionalButtons={customButtons} />
}

export default GroupMemberDropdown
