import { ColumnDef } from "@tanstack/react-table"
import { useParams } from "react-router-dom"

import UsersTable from "@components/Widgets/UsersTable/UsersTable.tsx"
import { UsersTableColumns } from "@components/Widgets/UsersTable/UsersTableColumns.tsx"

import AddUserToGroup from "@features/Admin/Groups/AddUserToGroup/AddUserToGroup.tsx"
import GroupMemberDropdown from "@features/Admin/Groups/Group/GroupMembers/GroupMemberDropdown.tsx"
import useGroupStore from "@features/Admin/Groups/stores/GroupStore.ts"

const GroupMembers = () => {
  const { id } = useParams()
  const { name, members } = useGroupStore((state) => state.group)

  const existingMemberIds = members.map((member) => member.user_id)

  const ActionButtons = [<AddUserToGroup id={id!} name={name} filteredIds={existingMemberIds} />]

  const rightCustomColumns: ColumnDef<ListUser>[] = [
    {
      id: "role",
      header: "Role",
      cell: ({ row }) => {
        const roles = row.original.group_roles

        return roles?.map((role) => (
          <span key={role} className="capitalize text-xs bg-gray-200 text-gray-600 rounded-full px-2 py-1 mr-1">
            {role}
          </span>
        ))
      },
    },
    {
      id: "actions",
      cell: ({ row }) => <GroupMemberDropdown row={row} data={row.original} groupId={id!} />,
    },
  ]

  const columns = [...UsersTableColumns.filter((c) => ["Display Name", "Email"].includes(c.id!)), ...rightCustomColumns]

  return (
    <>
      <UsersTable columns={columns} actionButtons={ActionButtons} endpoint={`/groups/${id}/members`} />
    </>
  )
}

export default GroupMembers
