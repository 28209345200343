import { FormEvent } from "react"

import { Button } from "@/shadcn-components/ui/button.tsx"

const RAChangePasswordForm = ({ handleSubmit }: { handleSubmit: (e: FormEvent<HTMLFormElement>) => void }) => (
  <div className={"flex flex-col gap-4"}>
    <form className={"flex flex-col gap-2 mt-4"} onSubmit={handleSubmit}>
      <label htmlFor={"password"}>New Password</label>
      <input type={"password"} id={"password"} name={"password"} className={"border rounded p-2 w-full"} />
      <label htmlFor={"confirmPassword"}>Confirm Password</label>
      <input
        type={"password"}
        id={"confirmPassword"}
        name={"confirmPassword"}
        className={"border rounded p-2 w-full"}
      />
      <Button type={"submit"}>Change Password</Button>
    </form>
  </div>
)

export default RAChangePasswordForm
