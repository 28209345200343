import { create } from "zustand"

interface StepSummaryStore {
  currentStepsResponse: StepsSummaryResponse | null
  setCurrentStepsResponse: (response: StepsSummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
}

const useStepSummaryStore = create<StepSummaryStore>((set) => ({
  currentStepsResponse: null,
  setCurrentStepsResponse: (currentStepsResponse) => set({ currentStepsResponse }),
  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
}))

export default useStepSummaryStore
