import SectionContainer from "../atom/SectionContainer"
import SurveyRangeSelector from "./SurveyRangeSelector"
import SurveysInsightView from "./SurveysInsightView"
import SurveysInsightProvider from "./provider/SurveysInsightProvider"

const SurveySection: React.FC = () => {
  return (
    <SectionContainer title="Surveys" headerControls={<SurveyRangeSelector allowRangeControl={true} />}>
      <SurveysInsightProvider>
        <SurveysInsightView />
      </SurveysInsightProvider>
    </SectionContainer>
  )
}

export default SurveySection
