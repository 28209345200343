import { create } from "zustand"

interface SurveySummaryStore {
  currentSurveyResponse: SurveySummaryResponse | null
  setCurrentSurveyResponse: (response: SurveySummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
}

const useSurveySummaryStore = create<SurveySummaryStore>((set) => ({
  currentSurveyResponse: null,
  currentOffsetDays: 0,
  currentLimitDays: 90,
  setCurrentSurveyResponse: (currentSurveyResponse) => set({ currentSurveyResponse }),
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
}))

export default useSurveySummaryStore
