import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/shadcn-components/ui/dialog.tsx"

import RAChangePassword from "@features/Home/components/RAHome/RAChangePassword.tsx"

const ChangePasswordRADialog = ({ triggerProps, row }: DropdownTriggeredDialogProps<ListUser>) => {
  const { user_id } = row.original

  return (
    <Dialog {...triggerProps}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Change Password for Study Participant</DialogTitle>
          <DialogDescription>Change the password for the study participant.</DialogDescription>
        </DialogHeader>
        <RAChangePassword afterClick={triggerProps.onOpenChange} userId={user_id} />
      </DialogContent>
    </Dialog>
  )
}

export default ChangePasswordRADialog
