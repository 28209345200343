import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import api from "@utilities/api.ts"

import ActivityGraphCardLoading from "../ActivityGraphCardLoading.tsx"
import useHomeTimeSummaryStore from "../store/HomeTimeSummaryStore.ts"

const HomeTimeSummaryProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentHomeTimeData = useHomeTimeSummaryStore((state) => state.setCurrentHomeTimeResponse)
  const currentOffsetDays = useHomeTimeSummaryStore((state) => state.currentOffsetDays)
  const currentLimitDays = useHomeTimeSummaryStore((state) => state.currentLimitDays)
  const { clientId } = useParams()

  const {
    data: homeTimeDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["homeTimeSummaryData", clientId, currentOffsetDays, currentLimitDays],
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/summary/home/${clientId}?offset_days=${currentOffsetDays}&limit_days=${currentLimitDays}`
      const { data, status } = await api.get<HomeTimeSummaryResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching Home Time data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && homeTimeDataResponse) {
      setCurrentHomeTimeData(homeTimeDataResponse)
    }
  }, [homeTimeDataResponse, isSuccess, setCurrentHomeTimeData])

  if (isLoading) return <ActivityGraphCardLoading label="Daily Home Time" />
  if (isError) return <ActivityGraphCardLoading label="Daily Home Time" isError={true} />

  return children
}

export default HomeTimeSummaryProvider
