import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons"

import SmallControlButton from "../../../../components/Buttons/SmallControlButton.tsx"
import useMoodInsightStore from "./store/MoodInsightStore.ts"

const MoodRangeSelector = () => {
  const currentOffsetDays = useMoodInsightStore((state) => state.currentOffsetDays)
  const setCurrentOffsetDays = useMoodInsightStore((state) => state.setCurrentOffsetDays)

  const dayRange = 21

  const getRangeStartText = () => {
    return `${dayRange + currentOffsetDays - 1}d ago`
  }

  const getRangeEndText = () => {
    if (currentOffsetDays === 0) {
      return "Today"
    }
    return `${currentOffsetDays}d ago`
  }

  const goToPrev = () => {
    setCurrentOffsetDays(currentOffsetDays + dayRange)
  }

  const goToNext = () => {
    if (currentOffsetDays - dayRange < 0) {
      return
    }
    setCurrentOffsetDays(currentOffsetDays - dayRange)
  }

  return (
    <div className="flex items-center space-x-1">
      <div className="text-sm flex items-center flex-row font-medium border rounded-xl bg-white px-3 py-1 select-none">
        <SmallControlButton onClick={goToPrev} icon={faArrowLeftLong} direction="left" />
        <div className="min-w-36 items-center text-center">
          {getRangeStartText()}
          <span className="text-xs"> - </span>
          {getRangeEndText()}
        </div>
        <SmallControlButton onClick={goToNext} icon={faArrowRightLong} direction="right" />
      </div>
    </div>
  )
}

export default MoodRangeSelector
